<template>
  <v-autocomplete
    class="select-user"
    chips
    required
    v-bind="$attrs"
    :items="validItems"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :item-text="textColumn"
    :value="value"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    @input="onChange"
  ></v-autocomplete>
</template>
<script>
// import bus from '@/store/modules/bus'
import { mapActions } from 'vuex'
import { find } from 'lodash'

export default {
  props: {
    value: {
      type: [Number, String],
      default: null,
    },

    items: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    placeholder: {
      type: String,
      default: 'Select user',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'full_name',
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },

    buildingId: {
      type: [String, Number],
      default: null,
    },

    propertyId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      fetching: false,
      users: [],
    }
  },

  computed: {
    validItems() {
      let items = [
        ...this.items,
        ...(this.users && this.users.length ? this.users : []),
      ]

      return !this.viaIndex
        ? items
        : items.map((item, i) => {
            return {
              index: i,
              ...item,
            }
          })
    },

    viaIndex() {
      return this.valueColumn.toLowerCase() === 'index'
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }
  },

  watch: {
    buildingId(newValue, oldValue) {
      this.fetch()
    },
    propertyId(newValue, oldValue) {
      this.fetch()
    },
  },

  methods: {
    ...mapActions({
      getUsersByProperty: 'user/getUsersByProperty',
    }),

    async fetch() {
      if (this.fetching) return
      this.fetching = true
      if (this.buildingId) {
        await this.getUsersByProperty({
          propertyId: this.propertyId,
        })
          .then((data) => {
            this.users = data
            this.$emit('populated', this.users)
          })
          .finally(() => {
            this.fetching = false
          })
      } else {
        this.fetching = false
      }
    },

    onChange(value) {
      this.$emit('input', value)
    },

    addUser(user) {
      const exist = find(this.users, { id: user.id })
      if (!exist) {
        this.users.push(user)
      }
    },

    updateUser(user) {
      const index = find(this.users, { id: user.id })
      if (index) {
        this.$set(this.users, index, user)
      }
    },

    removeUser(user) {
      const index = find(this.users, { id: user.id })
      if (index) {
        this.users.splice(index)
      }
    },
  },
}
</script>
