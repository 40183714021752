<template>
  <div class="date-picker">
    <Datepicker
      :value="date"
      :disabled-dates="disabledDates"
      :format="format"
      @selected="selectDate"
      placeholder="Date"
      :highlighted="highlighted"
    />
    <span class="date_error" v-if="error">
      {{ errorMessages }}
    </span>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Date Picker
 * https://www.npmjs.com/package/vuejs-datepicker
 * ==================================================================================
 **/
import Datepicker from 'vuejs-datepicker'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import { DAY_OF_WEEKS_OBJECT } from '@/utils/enums/BookingOption'

export default {
  name: 'DatePicker',
  components: { Datepicker },
  mixins: [SnackbarMixin],

  props: {
    value: {
      type: [String, Boolean, Object, Date],
      default: '',
    },

    disableDays: {
      type: Array,
      default: () => [],
      validator: function (value) {
        return value.every((item) => typeof item === 'string')
      },
    },
    format: {
      type: String,
      default: 'MMM dd, yyyy',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    },

    errorMessages: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      date: null,
      disabledDates: {
        days: [],
        to: new Date(Date.now() - 60000),
      },
      highlighted: {
        dates: [new Date(Date.now())],
      },
    }
  },

  watch: {
    disableDays(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setDisabledDates()
      }
    },
  },

  created() {
    this.setDisabledDates()
    this.init()
  },

  methods: {
    init() {
      this.date = this.value
    },

    setDisabledDates() {
      if (this.disableDays.length > 0) {
        const disables = Object.keys(DAY_OF_WEEKS_OBJECT)
          .filter((day) => !this.disableDays.includes(day))
          .map((day) => DAY_OF_WEEKS_OBJECT[day])
        this.disabledDates = { ...this.disabledDates, days: disables }
      } else {
        this.disabledDates = { ...this.disabledDates, days: [] }
      }
    },

    selectDate(value) {
      this.$emit('input', value.toISOString())
    },
  },
}
</script>
<style lang="scss">
.date_error {
  font-size: 12px;
  padding: 0 12px;
  color: var(--v-error-base);
}
.date-picker {
  .vdp-datepicker {
    input {
      height: 48px;
      width: 100%;
      background-color: #f1f3f4;
      padding: 0 12px;
      outline: none;
      border-radius: 5px;
      cursor: pointer !important;
      margin-top: 10px;
    }
  }
}
</style>
