<template>
  <div
    class="mx-4"
    id="booking-list"
    v-infinite-scroll="loadMore"
    :infinite-scroll-disabled="loading"
    infinite-scroll-distance="100"
  >
    <div class="appbar mt-7 mb-3">
      <v-tabs>
        <v-tab
          @change="
            defaultStatus = 'pending'
            activeAction()
          "
        >
          Pending
        </v-tab>
        <v-tab
          @change="
            defaultStatus = 'accepted'
            activeAction()
          "
        >
          Accepted
        </v-tab>
        <v-tab
          @change="
            defaultStatus = 'done'
            activeAction()
          "
        >
          Done
        </v-tab>
        <v-tab
          @change="
            defaultStatus = 'declined'
            activeAction()
          "
        >
          Declined
        </v-tab>
        <v-tab
          @change="
            defaultStatus = 'cancelled'
            activeAction()
          "
        >
          Cancelled
        </v-tab>
        <v-tab
          @change="
            defaultStatus = null
            activeAction()
          "
        >
          All
        </v-tab>
      </v-tabs>
      <v-spacer />
      <v-text-field
        v-model="filter.search"
        class="appbar__filter"
        ref="searchField"
        flat
        hide-details
        solo
        rounded
        clearable
        background-color="grey lighten-3"
        label="Search user, service and building"
        @input="search"
      ></v-text-field>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-10"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            v-show="false"
          >
            Bulk Actions
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in actions" :key="index">
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-data-table
      :headers="headers"
      :items="bookings"
      :server-items-length="totalBookings"
      :options.sync="options"
      :loading="loading"
      v-model="selected"
      :footer-props="{
        'items-per-page-options': [25, 50, 100, -1],
      }"
      @update:options="fetch"
      @click:row="editItem"
    >
      <template v-slot:item.user="{ item }">
        {{ item.userName }}
      </template>
      <template v-slot:item.bookable="{ item }">
        {{ item.phoneNumber }}
      </template>
      <template v-slot:item.type="{ item }">
        {{ item.user.email }}
      </template>
      <template v-slot:item.datetime_start="{ item }">
        <div v-if="item.bookable.booking_option === 'request'">
          {{ formatDateTime(item.datetime_start, item.datetime_end) }}
        </div>

        <div
          v-if="
            item.bookable.booking_option === 'calendar' &&
            item.time_slots?.length > 0
          "
        >
          <div
            v-for="(slot, index) in item.time_slots"
            :key="slot"
            :hidden="index > 3"
          >
            {{ formatDateTime(slot.start, slot.end) }}
          </div>
          <p v-if="item.time_slots.length > 2">Show more</p>
        </div>
      </template>
      <template v-slot:item.property="{ item }">
        {{ item.user?.property?.name }}
      </template>
      <template v-slot:item.building="{ item }">
        {{ item.buildingName }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.addedDate }}
      </template>
      <template v-slot:item.status="{ item }">
        <div style="text-align: center">
          <Status :status="item.statusLabel" />
        </div>
      </template>
    </v-data-table>
    <div
      class="d-flex justify-center mt-3"
      v-if="!(bookings.length > 0) && !loading"
    >
      <p>No match found.</p>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Booking list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import Status from '@/components/common/Status'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import BOOKING_STATUS from '@/utils/enums/BookingStatus'
import { dateFormat } from '@/utils/date'
import DATETIME_FORMAT from '@/utils/enums/DatetimeFormat'

export default {
  name: 'BookingsPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    Status,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultStatus: 'pending',
      defaultFilter: 'created_at',
      sortOrder: false,
      headers: [
        {
          text: 'User',
          value: 'user',
          width: '23%',
        },
        {
          text: 'Phone',
          value: 'bookable',
          width: '14%',
        },
        {
          text: 'Email',
          value: 'type',
          width: '10%',
        },
        {
          text: 'Property',
          value: 'property',
          width: '10%',
        },
        {
          text: 'Booking Date/Time',
          value: 'datetime_start',
          width: '20%',
        },
        {
          text: 'Status',
          value: 'status',
          width: '10%',
          align: 'center',
        },
        {
          text: 'Requested Date',
          value: 'created_at',
          width: '13%',
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
      pageTables: 0,
      itemsPerPageTables: 0,
      selected: [],
    }
  },

  created() {
    this.clearBookings()
    this.fetch()
  },

  computed: {
    ...mapState({
      bookings: (state) => state.booking.list,
      listMeta: (state) => state.booking.listMeta,
      totalBookings: (state) => state.booking.totalBookings,
      user: (state) => state.auth.user,
    }),

    statusFilter() {
      return [
        {
          text: 'All',
          value: null,
        },
        ...BOOKING_STATUS,
        {
          text: 'Deleted',
          value: 'deleted',
        },
      ]
    },

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },
  },

  methods: {
    ...mapActions({
      getBookings: 'booking/getBookings',
    }),

    ...mapMutations({
      clearBookings: 'booking/clearBookingList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
    }),

    filterList(filterValue) {
      this.clearBookings()
      this.fetch(null, filterValue)
    },

    activeAction() {
      this.clearBookings()
      this.fetch()
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetch(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearBookings()
      this.fetch()
    }, 600),

    async refreshBookingList() {
      this.clearBookings()
      await this.fetch()
    },

    async fetch(options, page = 1) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalBookings
            : options.itemsPerPage
          : 25,
        filter: this.defaultStatus,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }
      params.bookable_id = this.$route.params.id
      if (this.filter.search) {
        params.search = this.filter.search
      }
      this.loading = true
      await this.getBookings(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },

    formatDateTime(startTime, endTime) {
      return `${
        dateFormat(startTime, DATETIME_FORMAT.eventDateFormat) +
        ' ' +
        dateFormat(startTime, DATETIME_FORMAT.timeFormat) +
        ' - ' +
        dateFormat(endTime, DATETIME_FORMAT.timeFormat)
      }`
    },

    editItem(item) {
      this.$router.push({
        name: 'booking.details',
        params: { id: item.id },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar_tabs_btn {
  display: flex;
  width: 100%;
}
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  &__status {
    width: 100%;
    max-width: 300px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__status {
      margin-bottom: 15px;
    }

    &__status,
    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
.v-list-item__title {
  cursor: pointer;
}
</style>
